div[class^="Home-Box"]
    padding: 0
    display: grid 
    .home-box-text
        padding:70px 30px 70px 0  
        height: 100%
    button
        padding: 20px
        float: left
        cursor: pointer
        margin-right: 20px
        border: none
        border-radius: 8px

button.orange-button
    padding: 10px 20px
    cursor: pointer
    border: none
    border-radius: 4px

.Home-title
    margin: 0
    
.Home-title.right
    text-align: right


.Home-Box-2 
    padding-bottom:50px !important
    .home-box-text
        padding-top: 40px !important


.Home-Box-3 
    padding-top: 0px
    padding-bottom: 50px !important

    .home-box-text
        padding-top: 30px !important
    .Home-title

        padding: 10px 0 
    h2
        padding: 0
        margin: 0

.Home-text-right
    padding-left: 0px
    @media (min-width: 768px)
        padding-left: 20px
  


.Home-text-left
    padding-right: 0px
    @media (min-width: 768px)
        padding-right: 20px

div.form-text
    padding-right: 20px
    text-align: justify
    margin-top:50px

.container-form

    button
        padding: 10px !important
        border-radius: 8px !important
        min-width: 100px !important
        