@use '@cloudscape-design/design-tokens/index' as awsui


div.awsui-context-top-navigation
    header
        background-color : awsui.$color-background-home-header !important
        .title
            font-weight: 500
        .highlight
            color:#ff4713
            font-weight: 600    
            font-family: Arial, Helvetica, sans-serif