
div[class^="Home-Box"]
    text-align: justify


    .home-box-text

        text-align: justify
    button
        font-size:1.2em
        font-weight: 600

button.orange-button
    font-weight: 600

div[class^="Home-text"]
    font-size: 1.3em
    line-height: 1.5em
    font-weight: 400
    text-align: justify
    span.Highlight
        font-style: italic

.Home-title

    font-size:3.5em
    line-height: 1em

    @media (max-width: 600px)
        line-height: 1em
    font-weight: 500



.Home-Box-1

    .Highlight

        font-weight: 500


.Home-Box-2 

    .Home-title
        font-size: 3.5em
        padding-top: 30px
        line-height: 1.2em
        font-weight: 500
        .Highlight

            font-style: italic
    .Home-text
        text-align: justify
        .Highlight
            font-weight: 600
        span.Highlight-alt
            font-weight: 600


.Home-Box-3

    .Home-title
        font-size: 3em
        padding-top: 20px
        line-height: 1.2em
        .Highlight
            font-style: italic
    h2
        font-style: italic
        font-weight:600
        line-height: 1em


.container-form
    button
        font-size:1.2em !important
        font-weight: 600 !important
        text-align: center !important







