@use '@cloudscape-design/design-tokens/index' as awsui
$orange-color: #ff4713
$orange-ligh: #ff46137b
$blueish-color: #00819C
$blueish-light: #00ABCF
$blueish-lighter: #7ce7fc72

$blueish-dark: #004654
$greyish: #f9f9f9
$greyish-dark: #ddd

// TODO: color de seleccionar texto es muy oscuro


::selection
    background-color: $blueish-lighter

div[class^="Home-Box"]

    button
        color: white !important
        background-color: $orange-color

button.orange-button
    color: white !important
    background-color: $orange-color

div[class^="Home-text"]
    color: awsui.$color-background-home-header
    span.Highlight
        color: $orange-color

.Home-Box-1
    background-color : awsui.$color-background-home-header

    .Highlight
        color: awsui.$color-text-status-success !important


.Home-Box-2 
    background-color : white
    color:  awsui.$color-background-home-header


    .Home-title
        color: awsui.$color-background-home-header

        .Highlight
            color: $orange-color

    .Home-text
        color: awsui.$color-background-home-header


        .Highlight
            color: awsui.$color-border-status-success

        span.Highlight-alt
            color: $orange-color !important


.Home-Box-3

    background-color : $greyish//awsui.$color-text-notification-default


    .Home-title
        color: awsui.$color-background-home-header
    

        .Highlight
            color: $orange-color

    h2
        color: awsui.$color-background-home-header
        


    .Home-text
        color: awsui.$color-background-home-header

.Home-Box-3.white
    background-color : white

.Home-Box-3.different
    background-color : $blueish-light
    .Home-text
        color: white
        em
            color:$blueish-dark
    .Home-title
        color: white !important
    .Highlight
        color: $blueish-dark
.Contact-form 
    background-color : awsui.$color-text-body-secondary

    .Home-text
        color: awsui.$color-background-toggle-checked-disabled

    .Home-title
        color: awsui.$color-background-notification-red



div.Home-text.white
    color: awsui.$color-text-home-header-default !important



.container-form
    background-color : white !important
    border-radius: 5px !important
    border-color: $greyish-dark !important
    input
        background-color : $greyish !important
        border-color: $greyish-dark !important
    textarea
        background-color : $greyish !important
        border-color: $greyish-dark !important
    form 
        background-color : white !important
        
    label
        color: awsui.$color-background-toggle-checked-disabled !important

    div[class*="header"]
        background-color : white !important
        span
            color: awsui.$color-background-toggle-checked-disabled !important
    
    button
        background-color: $orange-color !important            
        border-color: $orange-color !important  
        color: white !important          

