div.first-image

    background-image: url("../img/woman_call_2.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    height: 95%
    min-height: 400px
    animation: bg-animation 60s ease-in-out infinite alternate
    @keyframes bg-animation 
        0% 
            background-size: 100%
  
        50% 
            background-size: 150%
  
        100% 
            background-size: 100%



div.second-image
    //border: 1px solid red

    background-image: url("../img/contact_center_reimaginado.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    height: 100%
    min-height: 400px
    max-height: 500px


div.second-image-overlay
    background-image: url("../img/contact_center_reimaginado_border.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    height: 100%
    z-index: 1
    animation: rotate 360s linear infinite

    @keyframes rotate
        0% 
            transform: rotate(0deg)
  
        100% 
            transform: rotate(360deg)
  


#bots
    background-image: url("../img/bots.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    height: 100%
    min-height: 100px

#omnichannel
    background-image: url("../img/omnicanalidad.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    height: 100%
    min-height: 300px

#voiceid
    background-image: url("../img/voiceid.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: top center
    height: 200%
    min-height: 200px

#wisdom
    background-image: url("../img/wisdom.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: top center
    height: 100%
    min-height: 200px

#profiles
    background-image: url("../img/profiles.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: top center
    height: 100%
    min-height: 200px


#campanas
    background-image: url("../img/campanas.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: top center
    height: 100%
    min-height: 400px